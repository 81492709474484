import { Helmet } from 'react-helmet-async';
// @mui
import { Container,Typography } from '@mui/material';
// components
import {  ProductList, } from '../sections/@dashboard/products';
// mock
import { products, videoProducts } from '../_mock/products';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  
  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Cloud and clouds </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Product Images
        </Typography>

        <ProductList products={products} />

        <Typography variant="h4" sx={{ mt: 9,  mb: 5 }}>
          Product Videos
        </Typography>
        <ProductList products={videoProducts} />




      </Container>
    </>
  );
}
