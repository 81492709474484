import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { updateLogin } from '../../../store/login.slice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const formData = useRef({ name: '', pwd: '' });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const validateForm = () => {
    const newErrors = {};

    if (!formData.current?.name?.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.current?.pwd?.trim()) {
      newErrors.pwd = 'Password is required';
    }

    console.log(formData.current,process.env.REACT_APP_USER_NAME,process.env.REACT_APP_PWD )
    if (
      formData.current?.name?.trim() !== process.env.REACT_APP_USER_NAME ||
      formData.current?.pwd.trim() !== process.env.REACT_APP_PWD
    ) {
      newErrors.pwd = "Email or password doesn't match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = () => {
    if (validateForm()) {
      dispatch(updateLogin(true));
      navigate('/dashboard', { replace: true });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("handleChange",name, value )
    formData.current = { ...formData.current, [name]: value };
  };

  return (
    <>
      <Stack spacing={3} sx={{mb: 5}}>
        <TextField
          name="name"
          label="Username"
          onChange={handleChange}
          error={!!errors?.name}
          helperText={errors?.name}
        />

        <TextField
          name="pwd"
          label="Password"
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          error={!!errors?.pwd}
          helperText={errors?.pwd}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
