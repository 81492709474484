import { faker } from '@faker-js/faker';

const PRODUCT_PHOTO_NAMES = [
  '3D Engine - Demo',
  '3D Engine - Demo',
  '3D Engine - Demo',
  '3D Engine - Demo',
  'TI Cycle - India',
  'TI Cycle - India',
  'TI Cycle - India',
  'Product of XCMG - China',
  'Product of XCMG - China ',
  'Product of XCMG - China',
  'Product of XCMG - China',
  'Product of XCMG - China',
  'Product of XCMG - China ',
  'Product of XCMG - China',
  'XCMG 3D branding - India',
  'XCMG 3D branding - India',
  'XCMG 3D branding - India',
];

const PRODUCT_VIDEO_NAMES = [
  'Animation render',
];

const products = PRODUCT_PHOTO_NAMES.map((name, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/products/product_${setIndex}.jpg`,
    src: `/assets/images/products/product_${setIndex}.jpg`,
    name,
    isPhoto: true
  };
});


const videoProducts = PRODUCT_VIDEO_NAMES.map((name, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: `/assets/images/products/video_thumbnail_${setIndex}.png`,
    src: `/assets/images/products/video_${setIndex}.mov`,
    name,
    isPhoto:false
  };
});


export { products ,videoProducts }
