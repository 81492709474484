import { createSlice } from "@reduxjs/toolkit";


const initialState ={
loggedIn : false
}

export const appSlice = createSlice({
  name: "APP_STATE",
  initialState,
  reducers: {
    updateLogin: (
      state,
      payload
    ) => {
        state.loggedIn = payload
        console.log(state,"State")
    }
  },
});

export const {
    updateLogin,
} = appSlice.actions;
export default appSlice.reducer;
